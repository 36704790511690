
export default {
  data() {
    return {
      image: require("../../assets/images/button-vector.svg"),
      isHidden: true,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },

  methods: {
    goUp() {
      return window.scrollTo(0, 0);
    },
    onScroll() {
      this.isHidden = window.scrollY < 500;
    },
  },
  computed: {
    displayButton() {
      if (process.client) {
        return window.innerHeight <= 1220 ? true : false;
      }
    },
  },
};
