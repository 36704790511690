import { render, staticRenderFns } from "./green.vue?vue&type=template&id=a1d0dd5e&scoped=true&"
import script from "./green.vue?vue&type=script&lang=js&"
export * from "./green.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1d0dd5e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/builds/clients/agily/agily-website-v2/front/components/Navbar.vue').default,GlobalsButtonVector: require('/builds/clients/agily/agily-website-v2/front/components/Globals/ButtonVector.vue').default,Footer: require('/builds/clients/agily/agily-website-v2/front/components/Footer/Footer.vue').default})
