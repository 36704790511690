import { clone, cloneDeep } from "lodash";

const STATUS_TYPES = {
  INIT: "init",
  SUCCESS: "success",
  ERROR: "error",
};

const ITEMS_PER_PAGE = 4;

const DefaultState = {
  books: {
    status: STATUS_TYPES.INIT,
    error: null,
    data: {},
  },
  last: {
    status: STATUS_TYPES.INIT,
    error: null,
    data: {},
  },
};

/* All states default */
/* cloneDeep infos : https://www.geeksforgeeks.org/lodash-_-clonedeep-method/ */
export const state = () => cloneDeep(DefaultState);

/* All states mutations */
export const mutations = {
  GET_BOOKS(state, payload) {
    state.books.data = payload;
    state.books.status = STATUS_TYPES.SUCCESS;
  },

  GET_LAST(state, payload) {
    state.last.data = payload;
    state.last.status = STATUS_TYPES.SUCCESS;
  },
};

/* All states getters */
export const getters = {
  getBooks: (state) => state.books,
  getLast: (state) => state.last,
};

/* All states actions */
export const actions = {
  //get all WhiteBooks
  async getBooks({ state, commit }, params) {
    try {
      const storeBooks = await this.$strapi.find(
        `livres?_sort=created_at:DESC`
      );

      commit("GET_BOOKS", storeBooks);
      commit("GET_LAST", storeBooks[0]);
    } catch (err) {
      console.error(err);
      console.error({ statusCode: err.status, message: err.response });
    }
  },
};
