
export default {
  props: {
    hideContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      footer: null,
    };
  },
  computed: {
    backgroundUrl() {
      return process.env.TWICPICS_EXTERNAL_URL + "/uploads/image_footer.png";
    },
  },
  async fetch() {
    try {
      this.footer = await this.$strapi.find("footer");
    } catch (err) {
      console.error(err);
      console.error({ statusCode: 404, message: err.response });
    }
  },
};
