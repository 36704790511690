import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=5ee8a496&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=5ee8a496&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ee8a496",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalsRichContent: require('/builds/clients/agily/agily-website-v2/front/components/Globals/RichContent.vue').default,FooterSection: require('/builds/clients/agily/agily-website-v2/front/components/Footer/Section.vue').default,Footer: require('/builds/clients/agily/agily-website-v2/front/components/Footer/Footer.vue').default})
