import Vue from "vue";
import { merge } from "lodash";
import "./modal-plugin.scss";

const modalStacks = [];

// Modal stack handlers
export function openModal(modalParams) {
  const { component, id } = modalParams;

  if (id === undefined || !component) {
    console.error("ModalStack: id and component param required to open modal");
    return;
  }

  if (modalStacks.find((modal) => modal.id === id)) {
    console.error("ModalStack: Duplicate modal with id " + id);
    return;
  }

  modalStacks.push(modalParams);
}

export function closeModal(modalId) {
  const modalIndex = modalStacks.findIndex(({ id }) => id === modalId);

  if (modalIndex === -1) {
    return;
  }

  modalStacks.splice(modalIndex, 1);
}

export function closeLastModal() {
  modalStacks.pop();
}

Vue.use({
  install(Vue) {
    // modal-stack component
    // This is the component that actually renders the modals on screen
    // We register it here in the plugin so we have an easy access to the modal stack data
    Vue.component("modal-stack", {
      data() {
        return {
          modalStacks,
        };
      },
      watch: {
        // Lock body scroll when modals are shown
        modalStacks(value) {
          if (value.length) {
            document.documentElement.classList.add("no-scroll");
            document.body.classList.add("no-scroll");
          } else if (!value.length) {
            document.documentElement.classList.remove("no-scroll");
            document.body.classList.remove("no-scroll");
          }
        },
      },
      render() {
        return (
          <div class="modal-stack">
            {this.modalStacks.map(
              (
                {
                  id,
                  component: Component,
                  data,
                  modalClass = ["p-4"],
                  noContainer = false,
                },
                index
              ) => {
                const baseZIndex = 9000 + index * 2;
                const closeEvent = data?.on?.close;
                const finalCloseEvent = () => {
                  closeModal(id);
                  closeEvent && closeEvent();
                };

                // This allows the modal component to just do `$emit('close')` to close the current modal
                const finalData = merge({}, data, {
                  on: {
                    close: finalCloseEvent,
                  },
                });

                if (noContainer) {
                  return (
                    <Component {...finalData} style={{ zIndex: baseZIndex }} />
                  );
                }

                return (
                  <div
                    class="modal-stack-item"
                    style={{ zIndex: baseZIndex - 1 }}
                  >
                    <div
                      class="modal-stack-backdrop"
                      on={{
                        click: finalCloseEvent,
                      }}
                    />
                    <div
                      class={["modal-stack-content", ...modalClass]}
                      style={{ zIndex: baseZIndex }}
                    >
                      <img
                        class="modal-close-button cursor-pointer"
                        src={require("~/assets/images/close.svg")}
                        width="20"
                        on={{ click: finalCloseEvent }}
                      />

                      <Component {...finalData} />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        );
      },
    });

    // Expose methods to handle modals
    Vue.prototype.$modal = {
      open: openModal,
      close: closeModal,
      closeLast: closeLastModal,
    };
  },
});

{/* <style lang="scss" scoped>

.modal-close-button {
 height: 
}

</style> */}
