
export default {
  props: {
    content: {
      type: String,
    },
    themeColor: {
      type: String,
    },
  },
};
