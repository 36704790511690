import { clone, cloneDeep } from "lodash";

const STATUS_TYPES = {
  INIT: "init",
  SUCCESS: "success",
  ERROR: "error",
};

const DefaultState = {
  categories: {
    status: STATUS_TYPES.INIT,
    error: null,
    data: [],
  },
  blog: {
    status: STATUS_TYPES.INIT,
    error: null,
    data: {},
  },
  blogTags: {
    status: STATUS_TYPES.INIT,
    error: null,
    data: [],
  },
  category: {
    status: STATUS_TYPES.INIT,
    error: null,
    data: {},
  },
  pagination: {
    firstPage: 1,
    secondPage: 2,
    fromPage: 0,
    toPage: 1,
    totalPage: null,
    totalItem: null,
    category: null,
  },
  featuredBlog: {
    status: STATUS_TYPES.INIT,
    error: null,
    data: [],
  },
  lastBlog: {
    status: STATUS_TYPES.INIT,
    error: null,
    data: [],
  },
};

/* All states default */
/* cloneDeep infos : https://www.geeksforgeeks.org/lodash-_-clonedeep-method/ */
export const state = () => cloneDeep(DefaultState);

/* All states mutations */
export const mutations = {
  GET_CATEGORIES(state, payload) {
    state.categories.data = payload;
    state.categories.status = STATUS_TYPES.SUCCESS;
  },
  GET_BLOG(state, payload) {
    state.blog.data = payload;
    state.blog.status = STATUS_TYPES.SUCCESS;
  },
  GET_BLOG_TAGS(state, payload) {
    state.blogTags.data = payload;
    state.blogTags.status = STATUS_TYPES.SUCCESS;
  },
  GET_CATEGORY(state, payload) {
    state.category.data = payload;
    state.category.status = STATUS_TYPES.SUCCESS;
  },
  GET_PAGINATION(state, payload) {
    state.pagination = payload;
  },
  GET_FEATURED_BLOG(state, payload) {
    state.featuredBlog.data = payload;
    state.featuredBlog.status = STATUS_TYPES.SUCCESS;
  },
  GET_LAST_BLOG(state, payload) {
    state.lastBlog.data = payload;
    state.lastBlog.status = STATUS_TYPES.SUCCESS;
  },
};

/* All states getters */
export const getters = {
  getCategories: (state) => state.categories,
  getBlog: (state) => state.blog,
  getBlogTags: (state) => state.blogTags,
  getCategory: (state) => state.category,
  getPagination: (state) => state.pagination,
  getFeaturedBlog: (state) => state.featuredBlog,
  getLastBlog: (state) => state.lastBlog,
};

/* All states actions */
export const actions = {
  //get all categories
  async getCategories({ commit }) {
    const storeCategories = await this.$strapi.find("articles-categories");
    commit("GET_CATEGORIES", storeCategories);
  },

  //get Articles for one category or all articles
  async getBlogTags({ state, commit }, params) {
    if (
      !params.category ||
      params.category === "all" ||
      params.category === "tous"
    ) {
      try {
        //get pagination (a faire en premier pour être sure , si on change de catégories , qu'on retourne bien a la page 1)
        const totalItem = await this.$strapi.count("articles");

        const totalPage = Math.ceil(totalItem / params.itemsPerPage);

        let toPage =
          state.pagination.category === params.category
            ? state.pagination.toPage
            : 1;

        commit("GET_PAGINATION", {
          ...state.pagination,
          totalItem: totalItem,
          totalPage: totalPage,
          category: params.category,
          toPage: toPage,
        });

        //get all articles (no category)

        const firstItem = (toPage - 1) * params.itemsPerPage;

        const storeBlogs = await this.$strapi.find(`articles?_start=${firstItem}&_limit=${params.itemsPerPage}&_sort=created_at:DESC&category_ne=null`);
        commit("GET_BLOG_TAGS", storeBlogs);
      } catch (err) {
        console.error(err);
        console.error({ statusCode: err.status, message: err.response });
      }
    } else {
      try {
        //get pagination
        let totalItem = await this.$strapi.find(
          `articles?&category.slug_eq=${params.category.toLowerCase()}`
        );
        totalItem = totalItem.length;

        const totalPage = Math.ceil(totalItem / params.itemsPerPage);

        let toPage =
          state.pagination.category === params.category
            ? state.pagination.toPage
            : 1;

        commit("GET_PAGINATION", {
          ...state.pagination,
          totalItem: totalItem,
          totalPage: totalPage,
          category: params.category,
          toPage: toPage,
        });

        //get articles for one category
        const firstItem = (toPage - 1) * params.itemsPerPage;

        const storeBlogs = await this.$strapi.find(`articles?_start=${firstItem}&_limit=${params.itemsPerPage}&_sort=created_at:DESC&category.slug_eq=${params.category.toLowerCase()}`);
        commit("GET_BLOG_TAGS", storeBlogs);
      } catch (err) {
        console.error(err);
        console.error({ statusCode: err.status, message: err.response });
      }
    }
  },

  //get featured articles
  async getFeaturedBlog({ commit }, params) {
    const storeFeaturedBlogs = await this.$strapi.find("articles", {
      _featured: true,
      _limit: params.limit,
    });
    commit("GET_FEATURED_BLOG", storeFeaturedBlogs);
  },

  //get last articles
  async getLastBlog({ commit }, params) {
    const storeLastBlogs = await this.$strapi.find("articles", {
      _limit: params.limit,
      _sort: "created_at:DESC",
    });
    commit("GET_LAST_BLOG", storeLastBlogs);
  },
};
