
import SuccessPopUp from "././SuccessPopUp.vue";

export default {
  name: "ContactUs",
  data() {
    return {
      listChoices: [
        { text: "Développement web", value: 25 },
        { text: "Développement mobile", value: 26 },
        { text: "Design", value: 27 },
        { text: "Infogérence", value: 28 },
        { text: "Intrapreunariat", value: 29 },
        { text: "Coaching & Workshops", value: 30 },
        { text: "Régie", value: 31 },
        { text: "Wordpress", value: 32 },
        { text: "SEO", value: 63 },
      ],
      errors: [],
      errorsFirstName: [],
      errorsLastName: [],
      errorsMail: [],
      errorsPhone: [],
      errorsUrl: [],
      errorsPoste: [],
      errorsMessage: [],
      errorsGlobalForm: [],
      errorsSelectedChoice: [],
      selectedChoice: [],
      first_name: null,
      last_name: null,
      mail: null,
      phone: null,
      enterprise_name: null,
      message: null,
      isSend: false,
      apiResponse: null,
      isEmpty: null,
      formIsValid: null,
    };
  },
  methods: {
    openPopUp() {
      if (this.formIsValid === true) {
        this.$modal.open({
          id: "SuccessPopUp",
          component: SuccessPopUp,
        });
        this.$modal.close("ContactUs");
      }
    },

    checkForm() {
      if (
        !this.first_name ||
        !isNaN(this.first_name) ||
        !this.last_name ||
        !isNaN(this.last_name) ||
        !this.mail ||
        !this.emailValid(this.mail) ||
        !this.phone ||
        isNaN(this.phone) ||
        !this.message
      ) {
        this.errorsGlobalForm.push(
          `Oups ! Un problème est survenu lors de l'envoi du formulaire...`
        );
      } else {
        this.formIsValid = true;
        this.errorsGlobalForm = [];
      }
      if (!this.first_name || !isNaN(this.first_name)) {
        this.errorsFirstName.push("Merci de renseigner un prénom");
        // this.first_name = this.errorsFirstName;
      } else {
        this.errorsFirstName = [];
      }

      if (!this.last_name || !isNaN(this.last_name)) {
        this.errorsLastName.push("Merci de renseigner un nom");
      } else {
        this.errorsLastName = [];
      }

      if (!this.mail) {
        this.errorsMail.push("Merci de renseigner un email valide");
      } else if (!this.emailValid(this.mail)) {
        this.errorsMail.push("Merci de renseigner un email valide");
      } else {
        this.errorsMail = [];
      }

      if (!this.phone || isNaN(this.phone)) {
        this.errorsPhone.push(
          "Merci de renseigner un numéro valide au format 06 / (+33)"
        );
      } else {
        this.errorsPhone = [];
      }

      if (!this.selectedChoice.length) {
        this.errorsSelectedChoice.push(
          "Merci de sélectionner une ou plusieurs options"
        );
      } else {
        this.errorsSelectedChoice = [];
      }

      if (!this.message) {
        this.errorsMessage.push("Ce champ est obligatoire(message)");
      } else {
        this.errorsMessage = [];
      }

      if (
        this.first_name &&
        this.last_name &&
        this.mail &&
        this.phone &&
        this.message
      ) {
        this.sendInfos();
      }
    },

    emailValid: function (email) {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email);
    },
    phoneValid: function (phoneNumber) {
      const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
      return regex.test(phoneNumber);
    },

    async sendInfos() {
      let org_id = null;

      const allInfos = [
        this.first_name,
        this.last_name,
        this.mail,
        this.phone,
        this.enterprise_name,
        this.selectedChoice,
        this.message,
      ];
      if (allInfos) {
        //recherche si l'entreprise existe deja
        const response = await this.$axios.$get(
          `https://agily.pipedrive.com/v1/organizations/search?term=${this.enterprise_name}&api_token=${process.env.API_PIPEDRIVE_TOKEN}`
        );
        if (response.data.items.length > 0) {
          //si oui , récupere son id
          org_id = response.data.items[0].item.id;
        } else {
          //si non , la créée et récupère son id
          const response = await this.$axios.$post(
            `https://agily.pipedrive.com/v1/organizations?api_token=${process.env.API_PIPEDRIVE_TOKEN}`,
            { name: this.enterprise_name }
          );
          org_id = response.data.id;
        }

        /* -------------------- */
        try {
          const response = await this.$axios.$get(
            `https://agily.pipedrive.com/v1/persons/search?term=${this.mail}&api_token=${process.env.API_PIPEDRIVE_TOKEN}`
          );

          if (response.data.items.length > 0) {
            const payload = {
              first_name: this.first_name,
              last_name: this.last_name,
              phone: this.phone,
              org_id,
              job_title: this.poste,
              label: 6,
              de74b4550c45208770b89788351208d7ee3be8aa: 14, // Le flag marketing qui correspond à Agily
              "79302aacc2fcb622d82c1ab46c9239251d807322": 19,
            };
            this.apiResponse = await this.$axios.$put(
              `https://agily.pipedrive.com/v1/persons/${response.data.items[0].item.id}?api_token=${process.env.API_PIPEDRIVE_TOKEN}`,
              payload
            );
          } else {
            // sinon si la personne n'existe pas alors on l'a créer
            const payload = {
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.mail,
              phone: this.phone,
              name: this.mail,
              org_id,
              job_title: this.poste,
              label: 6,
              "56743fcd3b0dbe26acca34a1dfe62668fde3c720": this.message, // message

              de74b4550c45208770b89788351208d7ee3be8aa: 14, // Le flag marketing qui correspond à Agily
              "79302aacc2fcb622d82c1ab46c9239251d807322": 19, // Le label personnalisé qui correspond à warm lead
            };

            this.apiResponse = await this.$axios.$post(
              `https://agily.pipedrive.com/v1/persons?api_token=${process.env.API_PIPEDRIVE_TOKEN}`,
              payload
            );
          }

          if (this.apiResponse.success === true) {
            const newLead = {
              title: `New lead ${this.first_name} ${this.last_name}`,
              person_id: this.apiResponse.data.id,
              // Key to get accompaniment through person_id
              "9c7c4cff329cb6b114f7dff809760b5ca77f4d4e": this.selectedChoice,
            };

            const response = await this.$axios.$post(
              `https://agily.pipedrive.com/v1/leads?api_token=${process.env.API_PIPEDRIVE_TOKEN}`,
              newLead
            );
            if (response.success === true) {
              this.openPopUp();
              this.$modal.close("ContactUs");
            }
          }

          this.isSend = true;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
