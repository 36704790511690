
export default {
  props: {
    content: {
      type: String,
    },
    url: {
      type: String,
    },
    external: {
      type: Boolean,
    },
  },
  computed: {
    urlWithTrailingSlash() {
      if (
        this.url &&
        this.url.slice(0, 1) === "/" &&
        this.url.slice(-1) !== "/"
      ) {
        return `${this.url}/`;
      }
      return this.url;
    },
  },
};
