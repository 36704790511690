
export default {
  name: "Input",
  props: {
    value: {
      type: [String, Number],
    },
    type: {
      type: String,
      default: "text",
    },
    title: {
      type: String,
    },
    titleWhite: {
      type: Boolean,
    },
    icon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: "black",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocus: false, // For phone input
    };
  },
};
