export default function ({ localePath }, inject) {
  inject('agilyLocalePath', (...args) => {
    let path = localePath(...args);
    if (path.replaceAll) {
      path = path.replaceAll('-1', '');
    } else { // xdJXHJIHERATAE?EAT?A§t!rt!e!t!
      while (path.indexOf('-1') !== -1) {
        path = path.replace('-1', '');
      }
    }
    return !path.endsWith('/') ? path + '/' : path;
  });
}
