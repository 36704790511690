import Vue from "vue";
import Vuetify from "vuetify";

Vue.mixin({
  computed: {
    isVerySmallScreen() {
      return this.$vuetify.breakpoint.xs;
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isMediumSmallScreen() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md;
    },
    isMediumScreen() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg;
    },
    isBigScreen() {
      return !this.isMediumScreen;
    },
  },
});

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 800,
      md: 1248,
      lg: 1440,
    },
  },
});
