// https://github.com/surmon-china/vue-awesome-swiper/issues/773#issuecomment-806481670
// NEED vue-awesome-swiper@4.1.1 and swiper@6.8.4 versions
import Vue from "vue";
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
} from "swiper/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

// import style
import "swiper/swiper-bundle.min.css";

SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);
Vue.use(getAwesomeSwiper(SwiperClass));
