import { render, staticRenderFns } from "./blue.vue?vue&type=template&id=080481fb&scoped=true&"
import script from "./blue.vue?vue&type=script&lang=js&"
export * from "./blue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080481fb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/builds/clients/agily/agily-website-v2/front/components/Navbar.vue').default,GlobalsButtonVector: require('/builds/clients/agily/agily-website-v2/front/components/Globals/ButtonVector.vue').default,Footer: require('/builds/clients/agily/agily-website-v2/front/components/Footer/Footer.vue').default})
