import { clone, cloneDeep } from "lodash";

const STATUS_TYPES = {
  INIT: "init",
  SUCCESS: "success",
  ERROR: "error",
};

const DefaultState = {
  videos: {
    status: STATUS_TYPES.INIT,
    error: null,
    data: {},
  },
};

/* All states default */
/* cloneDeep infos : https://www.geeksforgeeks.org/lodash-_-clonedeep-method/ */
export const state = () => cloneDeep(DefaultState);

/* All states mutations */
export const mutations = {
  GET_VIDEOS(state, payload) {
    state.videos.data = payload;
    state.videos.status = STATUS_TYPES.SUCCESS;
  },
};

/* All states getters */
export const getters = {
  getVideos: (state) => state.videos,
};

/* All states actions */
export const actions = {
  //get all Videos
  async getVideos({ state, commit }, params) {
    try {
      const storeVideos = await this.$strapi.find(`videos?_sort=created_at:DESC`);

      commit("GET_VIDEOS", storeVideos);
    } catch (err) {
      console.error(err);
      console.error({ statusCode: err.status, message: err.response });
    }
  },
};
