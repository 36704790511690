
import ContactUs from "./Globals/ContactUs.vue";

export default {
  data() {
    return {
      subNavigationStatus: "",
      navbar: null,
      mobileNavbar: false,
      mobileSublinks: "",
    };
  },
  computed: {
    subLinks() {
      return this.navbar.fields.filter(
        (element) => element.left_column_content
      );
    },
    navigationColor() {
      if (!this.mobileNavbar && this.subNavigationStatus === "") {
        return "navbar-close";
      } else {
        return "navbar-open";
      }
    },
  },
  methods: {
    setSubNavigationStatus(link) {
      if (link === this.subNavigationStatus) {
        this.subNavigationStatus = "";
      } else {
        this.subNavigationStatus = link;
      }
    },
    openForm() {
      this.subNavigationStatus = "";
      this.mobileNavbar = false;
      this.$modal.open({
        id: "ContactUs",
        component: ContactUs,
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.subNavigationStatus = "";
      this.mobileNavbar = false;
    });

    document.addEventListener("click", (event) => {
      const menu = this.$refs.navbar;
      if (menu && event) {
        if (!menu.contains(event.target)) {
          this.subNavigationStatus = "";
          this.mobileNavbar = false;
        }
      }
    });
  },
  async fetch() {
    try {
      this.navbar = await this.$strapi.find("navigation");
    } catch (err) {
      console.error(err);
      console.error({ statusCode: 404, message: err.response });
    }
  },
  fetchOnServer: true,
};
