
export default {
  head() {
    const baseUrl = process.env.AGILY_STATIC_URL;
    const { path } = this.$route; // get current route.
    const pathWithSlash = path.endsWith("/") ? path : `${path}/`; //append trailling slash

    return {
      link: [
        // { rel: "canonical", href: `${baseUrl}${pathWithSlash}` }, //set canocial
      ],
    };
  },
  computed: {
    isHideContent() {
      return [
        "/landing-jamstack/",
        "/landing-django/",
        "/landing-nodejs/",
        "/landing-nextjs/",
        "/landing-vuejs/",
        "/landing-agence-seo/",
        "/landing-headless-cms/",
        "/landing-python/",
        "/landing-flutter/",
        "/landing-react/",
        "/landing-ruby-on-rails/",
        "/landing-strapi/",
        "/landing-react-native/",
      ].includes(this.$route.path);
    },
  },
};
